.organisation-modal .modal-dialog {
    max-width: 450px;
}

.remove-button-holder {
    text-align: center;
    padding-bottom: 0.5rem;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
    .organisation-modal .modal-dialog {
        max-width: 330px;
    }


    .teams-images-holder img {
        width: 190px;
    }
}