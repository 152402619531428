.main-background {
    background: rgb(157, 35, 70);
    background: linear-gradient(180deg, rgba(157, 35, 70, 1) 0%, rgba(32, 40, 116, 1) 70%);
}


.main-background-landing {
    background: rgb(157, 35, 70);
    background: linear-gradient(180deg, rgba(157, 35, 70, 1) 0%, rgba(32, 40, 116, 1) 40%);
}

::-webkit-scrollbar {
    height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #90bbf1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #476f88;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.full-screen-height {
    height: 100vh;
}

.full-width {
    width: 100%;
}

html {
    background-color: black;
}

.form-control.is-invalid, .form-control.is-valid {
    background-position: right calc(.375em + .6000rem) center;
}

::-webkit-scrollbar-thumb {
    background: #8c8b8b;
}

::-webkit-scrollbar-track {
    background: #e6e6e6;
}

.slate-background {
    background-color: #171717;
}

.slate-background-transparent {
    background-color: rgba(23, 23, 23, 0.5);
}

.slate-background-transparent .hover-support:hover {
    background-color: rgba(23, 23, 23, 0.7);
}

.title-holder {
    background-color: rgba(0, 0, 0, 0.5);
}

.title {
    text-align: center;
    font-size: 3rem;
    margin: 0 auto;
    font-weight: bold;
    color: white;
    padding: 3rem;
}

.panel-title {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
}

.extra-small-screens-only {
    display: none;
}

.save-label {
    padding: 0.8rem;
}

.save-label span {
    color: #414141;
}

.dirty-button {
    box-shadow: 0 0 25px 0 #45a4ff;
}

.modal-dialog {
    top: 50%;
    margin: 0 auto;
    -webkit-transform: translate(0, -50%) !important;
    transform: translate(0, -50%) !important;
}

.modal-body {
    width: 100%;
    margin: 0 auto;
}

.modal-title {
    font-size: 1.2rem;
}

.modal-header .btn-link {
    padding: 0
}


.react-datepicker {
    zoom: 1.1;
}

.react-datepicker__header--time {
    height: 6rem;
}

.medium-and-above-screens {
    display: none;
}

.form-row > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
}

.loading-token-overlay {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.loading-token-overlay .loading-component-button {
    color: white;
}

.landscape-mode-warning {
    width: 0;
    height: 0;
}

.landscape-mode-warning > img {
    display: none;
}

textarea.form-control {
    resize: none;
}

/* Small screen landscape warning */
@media screen and (max-device-width: 760px) and (min-aspect-ratio: 13/9) {
    .landscape-mode-warning {
        height: 130%;
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;

        z-index: 977;
        background-color: rgba(20, 20, 20, 0.8)
    }

    .landscape-mode-warning > img {
        top: 50%;
        left: 50%;
        position: fixed;
        margin-left: -75px;
        margin-top: -75px;
        display: block;
    }
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {

    .main-background {
        background: linear-gradient(180deg, rgba(157, 35, 70, 1) 0%, rgba(32, 40, 116, 1) 50%);
    }

    .title {
        zoom: 0.5;
    }

    .panel-title {
        font-size: 1.1rem;
    }

    .extra-small-screens-only {
        display: inline;
    }

    .react-datepicker {
        zoom: 0.75;
    }
}

@media screen and (min-width: 800px) {
    .medium-and-above-screens {
        display: inline;
    }
}