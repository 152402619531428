.tasks-holder {
    min-height: 40vh;
    margin-top: 0.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 5px;
    text-align: center;
    padding: 0.5rem;
}

.tasks-column {
    display: table;
    width: 100%;
    height: 100%;
}

.task-column-header {
    color: white;
}

.tasks-column .full-width {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.tasks-column .full-width > * {
    margin: 0 auto;
}

.task {
    padding: 0.2rem;
    width: 100%;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    font-size: 0.95rem;
}

.task.overdue {
    border-color: #ff000c;
}

.other-task {
    border: 1px solid #007bff;
}

.tasks .tasks-col {
    min-height: 35vh;
}

.tasks .tasks-col:not(:first-child) {
    border-left: 1px solid white;
}

.tasks .ddcontainer {
    width: 100%;
}

.tasks .ddcontainersource {
    width: 100%;
}


.drop-target-holder {
    display: flex;
    align-items: center;
}

.drag-area {
    min-height: 30vh;
    height: 100%;
}

.tasks-column .droptarget {
    height: 10px;
    width: 100%;
    padding: 0;
}

.task-goal-display {
    font-size: .9rem;
}

.shared-with-display {
    font-size: .8rem;
}

.show-more {
    color: #007bff;
}

.expand-button > .fa {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.drag-handle {
    background-color: rgba(140, 199, 255, 0.10);
    border-radius: 5px;
}

.pending-acceptance-task {
    border: 1px dashed #007bff;
    line-height: 1.1rem;
    padding: 2px;
}

.pending-acceptance-task > span {
    font-size: 0.8rem;
}

.task .btn-link {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6px;
    padding-right: 6px;
}

.comments-exist {
    color: white;
}

.sign-up-modal .form-check-label {
    color: black;
}

.weekly-occurrence .form-check-label {
    color: white;
}

.modal-dialog {
    max-width: 350px;
}

.recurring-monthly-date-select {
    width: 80%;
    max-width: 75px;
    background-color: grey;
    color: white;
    border: 1px solid #007bff;
    display: inline;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.recurring-monthly-holder {
    margin: 0 auto;
}

.recurring-yearly-date-select {
    max-width: 60px;
    display: inline;
    width: 30%;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    background-color: grey;
    color: white;
    border: 1px solid #007bff;
    padding: 6px;
    vertical-align: middle;
}

.recurring-yearly-month-select {
    width: 60%;
    max-width: 150px;
    display: inline-block;
    margin-right: 0.5rem;
}

.shared-goal-panel .button-group {
    display: flex;
    align-items: center;
}

.shared-goal-panel .goal-display {
    text-align: center;
    padding: 1rem;
    font-weight: bold;
}

.shared-goal-panel .button-group .fake-button {
    margin: 0 auto;
}


#time-tracking-select {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.task-holder.highlight {
    background-color: rgba(255, 255, 255, 0.1);
}

.task-filter-display {
    font-size: 0.9rem;
    color: white;
    line-height: 0.9rem;
}

.task-filter-display hr {
    background-color: rgba(255, 255, 255, 0.13);
    margin: 0.2rem;
}

.automation-button {
    padding: 4px;
}

.popover-body {
    width: 200px;
    background-color: #272727;
}

.popover-body .btn-link {
    padding: 3px;
    color: white;
    font-size: 0.9rem;
    text-align: left;
}

.popover-body hr {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    border-top: 1px solid white;
}

.task-column-header {
    color: white;
    font-size: 1.3rem;
    line-height: 1.3rem;
    vertical-align: middle;
    text-align: center;
}

.task-column-header-holder.dragging {
    text-decoration: underline;
    min-width: 250px;
    color: white;
}

.column-edit {
    padding: 0.2em;
}

.task-column-edit-holder {
    margin: 0 auto;
    width: fit-content;
}

.task-column-edit {
    max-width: 150px !important;
}

.task .assignee {
    color: #0090ed;
}

.due-date.overdue {
    color: red;
}

.button-with-numbers {
    padding-right: 0 !important;
}

.tasks-column .ddcontainerghost {
    min-width: 340px;
    background-color: rgba(140, 199, 255, 0.1);
}

.task-column-header-holder .loading-component-button, .task-column-edit-holder .loading-component-button {
    margin-left: 10px;
    color: white;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .tasks .col-lg.col-md:not(:first-child) {
        min-height: 30vh;
        border-left: none;
        border-top: 1px solid white;
    }

    .tasks-column input, .tasks-column button {
        zoom: 0.8;
    }

}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .tasks .col-lg:not(:first-child):not(.col-md) {
        min-height: 30vh;
        border-left: none;
        border-top: 1px solid white;
    }

    .tasks-column .ddcontainerghost {
        min-width: 320px;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {

    .tasks .tasks-col {
        height: auto;
        min-height: auto;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem;
        padding-left: 0;
        padding-right: 0;
    }

    .tasks-holder {
        margin-top: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .task {
        font-size: 0.8rem;
    }

    .task span {
        font-size: 0.8rem;
    }

    .task .due-date {
        font-size: 0.8rem;
    }

    .task-column-header {
        font-size: 1rem;
    }

    .tasks-column .tasks-column .dropdown-item {
        font-size: 0.7rem;
    }

    .tasks-column .ddcontainerghost {
        min-width: 220px;
    }

}