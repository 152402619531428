.inline-survey {
    margin: 0 auto;
    text-align: center;
    background-color: inherit;
    font-size: 1.1em;
}

.inline-survey-button {
    margin: 0 auto;
}

.survey-holder, .star-ratings {
    padding: 0.6rem;
}

.inline-survey .fade {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    opacity: 1;
}