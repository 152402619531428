.feedback-bar {
    height: 2.5rem;
    position: fixed;
    bottom: 0;
    text-align: left;
    left: 6.5rem;
    z-index: 3;
    margin-bottom: 0.7rem;
}

.feedback-bar button {
    z-index: 3;
}

@media screen and (min-width: 0px) and (max-width: 1400px) {

    .feedback-bar {
        left: 4.7rem;
    }
}