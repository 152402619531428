.automation-selection {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.automation-close {
    color: red !important;
    padding: 0 0 0 0.3rem;
}

.automation-close:hover {
    text-decoration: underline;
    cursor: pointer;
}

.automation-panel {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.automation-scroll-panel-holder {
    max-height: 60vh;
    overflow-y: scroll;
}

.automation-panel-holder {
    padding-right: 0.75rem;
}

.automation-is-loading {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.automation-remove-button-holder {
    text-align: center;
    padding-left: 1.5rem;
}

.automation-line-separator {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.automation-modal .modal-dialog {
    max-width: 450px;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
    .automation-modal .modal-dialog {
        width: 330px;
    }

    .automation-images-holder img {
        width: 190px;
    }
}