.fake-button {
    min-width: 150px;
    background-color: black;
    padding: 1rem;
    border-radius: 4px;
    color: white;
    display: table;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
}

.fake-button.sm {
    padding: 0.5rem;
    font-size: 0.9rem;
}

.fake-button:hover {
    background-color: #8b8b8b;
    color: black;
    cursor: pointer;
}

.fake-button.disabled {
    background-color: #8b8b8b;
    cursor: not-allowed;
    color: #bbbbbb;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {

    .fake-button {
        padding: 0.5rem;
        font-size: 0.9rem;
    }

}