.side-bar-container-minimised, .side-bar-minimised {
    height: 100vh;
    width: 2rem;
}

.side-bar-container, .side-bar {
    height: 100vh;
    width: 6rem;
}

.side-bar-friends, .side-bar-notifications, .side-bar-boards, .side-bar-settings {
    left: 6rem;
    font-size: 0.9rem;
    height: auto;
    position: fixed;
    border-radius: 2px;
}

.side-bar, .side-bar-minimised  {
    position: fixed;
    z-index: 300;
}

.side-bar-selection, .side-bar-friends, .side-bar-notifications, .side-bar-boards {
    color: #bcbcbc;
    border: 1px solid #272727;
    border-radius: 3px;
}

.side-bar-friends hr {
    border-color: #bcbcbc;
}

.side-bar-selection {
    width: 100%;
    height: 7rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.side-bar-selection.disabled {
    color: #565656;
    cursor: not-allowed;
}

.side-bar-selection:not(.disabled):hover, .side-bar-selection + .selected {
    background-color: white;
    color: black;
    cursor: pointer;
}

.side-bar-selection .inner {
    margin: 0 auto;
    font-size: 0.8rem;
}


.side-bar-settings {
    top: 7rem;
}

.side-bar-friends {
    padding: 0.5rem;
    width: 30rem;
    min-height: 10rem;
    top: 21rem;
}

.side-bar-notifications {
    width: 30rem;
    top: 14rem;
}

.side-bar-boards {
    top: 28rem;
    width: 30rem;
}

.boards-chooser {
    padding: 5px 15px 5px 10px;
}


.invite-friends-button {
    margin: 0.5rem auto;
}

.friend-request, .notification {
    display: flex;
    align-items: center;
}

.notification {
    width: 100%;
    border: 1px solid #3b3b3b;
    padding: 0.3rem;
    font-size: 0.9rem;
}

.notification:hover {
    cursor: pointer;
}

.notification.seen {
    background-color: #5b5b5b;
}

.notification:hover:not(.seen) {
    background-color: #8b8b8b;
    color: black;
}

.notification-display {
    background-color: #272727;
    max-height: 300px;
    overflow-y: auto;
}

.side-bar-title {
    padding: 0.5rem;
}

.friend-request .btn-link {
    padding: 3px;
    width: 24px
}

.friend-request .fa {
    margin-right: 0.5rem;
}

.notification-display-no {
    font-weight: 500;
    color: deepskyblue;
    font-size: 1.2rem;
}

.copy-link:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

.copy-link:active {
    color: yellow !important;
}

@media screen and (min-width: 0px) and (max-width: 1400px) {
    .side-bar {
        width: 4.5rem;
    }

    .side-bar-container {
        width: 6.1rem;
    }

    .side-bar-friends, .side-bar-notifications, .side-bar-settings, .side-bar-boards {
        left: 4.5rem;
        font-size: 0.7rem;
    }

    .side-bar-settings {
        top: 5rem;
    }

    .side-bar-notifications {
        top: 10rem;
        width: 16rem;
    }

    .side-bar-friends {
        top: 15rem;
        width: 16rem;
        line-height: 0.8rem;
    }

    .side-bar-boards {
        top: 20rem;
        width: 75%;
        max-width: 600px;
    }

    .side-bar-boards button, .side-bar-boards input {
        font-size: 0.7rem;
    }

    .notification {
        font-size: 0.8rem;
    }

    .side-bar-selection {
        height: 5rem;
    }

    .side-bar-selection .fa {
        font-size: 1.3rem;
    }

    .side-bar-selection .inner {
        font-size: 0.7rem;
    }

    .friend-request {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }

    .side-bar-friends hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}