.file-holders {
    padding-top: 1rem;
    line-height: 0.8rem;
}

.uploadFile {
    color: ghostwhite;
    font-size: 0.8rem;
}

.uploadFile .btn-link {
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0;
    padding-bottom: 0;
}

.task-upload-loader .loading-component-button {
    color: white;
}

.file-upload .progress-bar-label {
    color: white;
}

.file-error {
    font-size: 0.8rem;
    white-space: pre;
}