.pomodoro-bar {
    right: 0;
    position: fixed;
    bottom: 0;
    text-align: right;
}

.pomodoro-bar button {
    z-index: 3;
}

.focus-button-holder {
    float: right;
    margin-right: 0.2rem;
    margin-bottom: 0.7rem;
    border-radius: 0.6rem;
}

.pomodoro-timer {
    background-color: rgba(52, 58, 64, 0.7);
    color: white;
    min-width: 160px;
    float: right;
    margin-right: 0.2rem;
    margin-bottom: 0.7rem;
    border-radius: 0.6rem;
    padding: 5px;
    text-align: center;
}

.pomodoro-timer-help {
    text-align: left;
    max-width: 300px;
    font-size: 0.9rem;
}

.pomodoro-timer-help li {
    margin-bottom: 0.5rem;
}

.pomodoro-timer hr {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.pomodoro-timer .current-timer {
    font-size: 3rem;
    text-align: center;
}

.pomodoro-timer .btn {
    padding: 2px;
    color: white;
}

.pomodoro-timer .btn:hover {
    color: lightblue !important;
}

.pomodoro-timer .btn.active {
    color: #45a4ff;
}

.pomodoro-timer .btn-group {
    display: flex;
    align-items: center;
}

.timer-text {
    text-align: center;
    font-size: 0.8rem;
}