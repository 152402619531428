.comments-text-holder {
    padding: 0.5rem;
}

.comment {
    padding: 0.5rem;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 0.5rem;
    font-size: 0.85rem;
    white-space: pre-line;
    vertical-align: center;
}

.my-comments {
    text-align: left;
    background-color: rgba(140, 199, 255, 0.1);
}

.other-comments {
    text-align: right;
    background-color: rgba(205, 255, 47, 0.1);
}

.comment-text {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.comment a {
    color: #5ea5e8;
    word-break: break-word;
}