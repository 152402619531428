.pricing-display {
    text-align: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
}

.pricing-button-holder {
    margin: 1rem;
    text-align: center;
}

.pricing-display-bottom {
    border-bottom-right-radius: 5rem;
    border-bottom-left-radius: 5rem;
}

.pricing-display h2, .pricing-display h3, .pricing-display h4, .pricing-display h5 {
    color: white;
}

.pricing-display h6 {
    color: darkgrey;
}


.pricing-display hr {
    background-color: #a9a9a9;
}


.pricing-display table {
    text-align: center;
    width: 100%;
    color: white;
}

.pricing-display tr {
}

.pricing-display td {
    height: 2.5rem;
}

.pricing-display tr:nth-child(even) {
    background: #222222;
}

.price-holder {
    height: 160px;
}

.billing-button-holder {
    padding: 0.5rem;
}

.billing-button-holder.hide > .btn {
    visibility: hidden;
}

@media screen and (min-width: 768px) {
    .pricing-display-container {
        min-height: 100vh;
        display: flex;
        align-items: center;
    }
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .price-holder {
        height: auto;
    }

    .billing-button-holder.hide > .btn {
        display: none;
    }
}