.goals-holder {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 5px;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /*z-index: -1;*/
}

.goals-container {
    width: 100%;
    padding: 0.1rem;
}

.goals-column {
    display: table;
    width: 100%;
}

.goals-column .full-width {
    width: 100%;
    display: flex;
    align-items: center;
}

.goals-column .full-width > * {
    margin: 0 auto;
}

.goal {
    padding: 5px;
    line-height: 1em;
    width: 80%;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
}

.goal > span {
    vertical-align: middle;
}

.goal.overdue {
    border-color: #ff000c;
}

.due-date.overdue {
    color: red;
}

.goal-label {
    height: 20px;
    width: 20px;
}

.dropdown-toggle {
    padding-right: 20px;
}

.label {
    float: left;
    height: 20px;
    width: 100%;
}

.goals-column .droptarget {
    height: 10px;
    width: 100%;
    padding: 0;
}

.drop-target-holder:first-child .droptarget, .drop-target-holder:last-child .droptarget {
    height: 15px;
}

.goals-column .ddcontainer {
    width: 100%;
}

.goals-column .ddcontainersource {
    width: 100%;
}

.goals-container .ddcontainerghost {
    width: 60%;
    max-width: 800px;
}

.goal .btn-link {
    vertical-align: middle;
    border: 0;
    padding-top: 0;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 0;
}

.other-goal {
    border: 1px solid #007bff;
}

.progress-bar-holder {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.progress-bar {
    background-color: #22238b;
    padding: 0.6rem;
}

.progress-bar-label {
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    line-height: 1.4rem;
    font-size: 0.9rem;
}

.progress {
    height: 1.4rem;
    background-color: #17202A
}

.goal .btn-link {
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6px;
    padding-right: 6px;
}

.goal .goal-drag-handle {
    background-color: rgba(140, 199, 255, 0.10);
    border-radius: 5px;
    padding: 0.2rem;
}

.done-drop-zone {
    border: 1px solid rgba(0, 123, 255, 0.5);
    color: #007bff;
    border-radius: 5px;
    margin: auto 1rem;
}

.selected {
    background-color: rgba(71, 111, 136, 0.2);
}

@media screen and (min-width: 0px) and (max-width: 800px) {

    .goal {
        font-size: 0.9rem;
        line-height: 1.0rem;
    }

    .progress-bar-label {
        font-size: 0.75rem;
    }

    .goal > span {
        font-size: 0.9rem;
    }

    .goal .due-date {
        font-size: 0.8rem;
    }

    .goals-container {
        padding: 0.5rem;
    }

    .goals-container .ddcontainerghost {
        width: 75%;
    }

    .goal .btn-link {
        padding-top: 3px;
    }

    .shared-with-display {
        font-size: 0.7rem;
    }

    .dropdown-item  {
        font-size: 0.7rem;
    }

    .goals-column input, .goals-column button {
        zoom: 0.8;
    }

}
