.login-notices-panel {
    text-align: center;
}

.login-notices .modal-dialog {
    max-width: 450px;
}

.images-holder img {
    border: 3px solid black;
    border-radius: 5px;
}

.login-text-holder {
    padding: 5px;
}

.login-text-holder span {
    font-size: 0.9rem;
}

.new {
    font-size: 0.8rem;
    color: white;
    border: 5px solid #007bff;
    border-radius: 14px;
    background-color: #007bff;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
    .login-notices .modal-dialog {
        width: 310px;
    }

    .images-holder img {
        width: 240px;
    }
}