.button-center {
    margin: 0 auto;
}

.button-social-network {
    width: 250px
}

.button-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.button-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.help-block {
    padding-top: 0.5rem;
    font-size: 0.8rem;
    color: #898989;
    line-height: 0.6rem;
}

.help-block p {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.bottom-sign-up-links {
    text-align: center;
}

.bottom-sign-up-links > .btn-link {
    font-size: 0.8rem;
    padding: 5px
}

.modal-dialog {
    max-width: 350px;
}

.modal-header {
    padding: 10px;
    align-items: center;
}

.sign-up-modal hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.login-failure {
    color: red;
    text-align: center;
    padding-bottom: 5px;
    font-size: 1rem;
}

.reset-password-message {
    text-align: center;
    padding-bottom: 5px;
    font-size: 0.8rem;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
    .modal-dialog {
        max-width: 280px;
    }

    .button-social-network {
        width: 250px
    }

    .login-failure {
        font-size: 0.8rem;
    }
}