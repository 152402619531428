.loading-component-button {
    -webkit-animation: rotation 2s infinite linear;
    margin-right: 7px;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}