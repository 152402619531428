
.sign-up-panel {
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.welcome-instruction {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    color: white;
    margin: 0 auto 2rem auto;
}

.show-case {
    text-align: center;
    margin: 0 auto 2rem;
}

.show-case img {
    width: 900px;
}

.landing-page-holder, .landing-page-holder-alt {
    color: white;
    text-align: center;
    padding-bottom: 2rem;
}

.try-pricing-holder {
    max-width: 400px
}

.no-bottom-padding {
    padding-bottom: 0;
}

.landing-page-holder {
    background-color: rgba(0, 0, 0, 0.67);
}

.landing-page-section-holder {
    padding: 2rem;
}

.landing-page-section-title {
    font-size: 1.2rem;
}

.landing-page-section-text {
    color: rgb(191, 185, 203);
    padding-top: 0.5rem;
}

.show-case-landing img {
    width: 100%;
    max-width: 950px;
    border: 2px solid black;
    box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.23);
    border-radius: 5px;
}

.welcome-instruction .first-line {
    font-size: 1.4rem;
    color: #8b8b8b;
}

.welcome-instruction .second-line {
    font-size: 1.4rem;
    color: #bbbbbb;
}

.welcome-instruction .third-line {
    font-size: 1.4rem;
    color: white;
}

.hint-bar {
    background-color: #171717;
    width: 100%;
}

.hint-bar .col-sm {
    color: white;
    text-align: center;
    padding: 1.5rem;
}

.sign-up-panel .form-control {
    margin-bottom: 0.5rem;
    border: 0;
}

.try-now, .pricing-button {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
}

.pricing-button .fake-button {
    background-color: #333333;
}

.pricing-button .fake-button:hover {
    background-color: #8a8a8a;
}


.second-try-now {
    padding-top: 1rem;
}

.center-button {
    margin: 0 auto;
}

.show-case img {
    width: 750px;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 46.00%;
    max-width: 900px;
    height: 0;
    margin: 1rem auto;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Extra Small Screens */
@media screen and (min-width: 0px) and (max-width: 800px) {

    .try-pricing-holder {
        max-width: 365px
    }

    .show-case-landing img {
        display: none;
    }

    .welcome-instruction {
        padding: 1rem;
        margin: 0 auto 1rem auto;
    }

    .welcome-instruction .first-line {
        font-size: 1.1rem;
    }

    .welcome-instruction .second-line {
        font-size: 1.1rem;
    }

    .welcome-instruction .third-line {
        font-size: 1.1rem;
    }

    .hint-bar .text {
        font-size: 0.8rem;
    }

    .hint-bar .fa {
        font-size: 2.5rem;
    }

    .show-case img {
        width: 95%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1000px) {
    .show-case img {
        width: 600px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
    .show-case img {
        width: 700px;
    }
}