.plan-page {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
}

.title-bar {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.title-bar-logo {
    margin: 0 auto;
    zoom: 0.4;
}

.title-board-display {
    text-align: center;
    color: white;
    padding-bottom: 0.5rem;
}

.rest-of-page {
    width: 100%;
}

.form-holder {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
}

.form-holder .form-label {
    color: white;
}

.due-date {
    color: rgba(216,216,216,0.44);
}

.board-title-created-by {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.64);
    line-height: 0.5rem;
}