.modal-dialog {
    max-width: 350px;
}

select#time-zone {
    font-size: 0.8rem;
}

.time-zone-dialog .fake-button {
    margin: 0.5rem auto 0 auto;
}